import React, { useContext, useEffect } from 'react';
import CategoryIcons from '../../components/Categories/CategoryIcons/CategoryIcons.jsx';
import Advance from '../../components/Advance/Advance.jsx';
import CategoryCard from '../../components/Category/Card/CategoryCard.jsx';
import Specials from '../../components/Specials/Specials.jsx';
import { CategoriesContext } from '../../services/context/CategoriesContext.js';
import { EligibilityContext } from '../../services/context/EligibilityContext.js';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';
import { UserDetailsContext } from '../../services/context/UserDetailsContext.js';
import { withProfiler } from '@sentry/react';

/**
 * @returns {JSX.Element} HomePage component
 */
function Home() {
  const userDetails = useContext(UserDetailsContext);
  const categories = useContext(CategoriesContext);
  const eligibility = useContext(EligibilityContext);
  const analyticsName = `${pagePrefix}: landing`;

  const categoryHeaders = [
    name => `${name} brands for you`,
    name => `More ${name.toLowerCase()}, less money`,
    name => `All your ${name.toLowerCase()} needs`,
  ];

  useEffect(() => {
    if (userDetails.userId && userDetails.email && userDetails.msisdn) {
      const userInfo = {
        visitor_id_user: userDetails.userId,
        visitor_id_asset_active: userDetails.msisdn,
        visitor_id_marketing_email: userDetails.email,
      };
      window.utag?.view({
        ...defaultAnalyticsVariables,
        page_name: analyticsName,
        event_name: [events.pageView],
        ...userInfo,
      });
    }
  }, [userDetails]);

  const loadedCategories = categories.filter(category => category.vendors.length);
  /** @type {JSX.Element[]} */
  const categoryCards = loadedCategories.map((category, index) => (
    <CategoryCard
      key={ category.id }
      category={ category }
      description={ categoryHeaders[index % categoryHeaders.length](category.name) }
    />
  ));

  return (
    <AnalyticsPageContext.Provider value={ analyticsName }>
      {
        categories
        && <Specials />
      }
      <CategoryIcons />
      {
        eligibility?.tcl
        && <Advance amountAvailable={ eligibility.tcl } />
      }
      {
        categoryCards.length > 0 ? categoryCards
          : Array.from({ length: 4 }).map((_, index) => <CategoryCard key={ index } />)
      }
    </AnalyticsPageContext.Provider>
  );
}

export default withProfiler(Home);
