import React from 'react';
import BearerTokenController from './BearerTokenController.jsx';
import CategoriesController from './CategoriesController.jsx';
import CartController from './CartController.jsx';
import EligibilityController from './EligibilityController.jsx';
import PropTypes from 'prop-types';
import HistoryController from './HistoryController.jsx';
import CurrentCouponController from './CurrentCouponController.jsx';
import RocketController from './RocketController.jsx';
import PaymentAmountController from './PaymentAmountController.jsx';
import SnackBarController from './SnackBarController.jsx';
import MiniAppController from './MiniAppController.jsx';
import UserDetailsController from './UserDetailsController.jsx';
import ConfigController from './ConfigController.jsx';
import MoneyFormatterController from './MoneyFormatterController.jsx';
import PromosController from './PromosController.jsx';
import OverScrollController from './OverScrollController.jsx';
import MaintenanceController from './MaintenanceController.jsx';
import AdvanceController from './AdvanceController.jsx';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function ContextController({ slot }) {
  const reducer = providers => {
    const [Provider, ...rest] = providers;
    if (rest.length === 0) {
      return <Provider slot={ slot } />;
    }

    return <Provider slot={ reducer(rest) } />;
  };

  return reducer([
    SnackBarController,
    OverScrollController,
    MaintenanceController,
    ConfigController,
    MoneyFormatterController,
    MiniAppController,
    UserDetailsController,
    BearerTokenController,
    EligibilityController,
    CategoriesController,
    PromosController,
    HistoryController,
    CartController,
    AdvanceController,
    CurrentCouponController,
    RocketController,
    PaymentAmountController,
  ]);
}

ContextController.propTypes = { slot: PropTypes.element };
