import React, { useContext } from 'react';
import './CategoryVendors.css';
import VendorCard from '../../Vendor/Card/VendorCard.jsx';
import PropTypeShapes from '../../../services/Constants/PropTypeShapes.jsx';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../services/Constants/GlobalRoutes.jsx';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../../services/context/AnalyticsPageContext.js';
import { Category } from '../../../services/Constants/ObjectDefinitions.js';

/**
 * @param {object} props - the props of the component
 * @param {Category} props.category - the Category to display
 * @returns {JSX.Element} CategoryVendors Component
 */
export default function CategoryVendors({ category }) {
  const isEmpty = !category;
  const navigateTo = useNavigate();

  const analyticsPage = useContext(AnalyticsPageContext);
  const clickVendor = vendor => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: analyticsPage,
      event_name: [events.interaction],
      link_id: `${pagePrefix}: ${category.name}: ${vendor.name}`,
    });
    navigateTo(ROUTES.VENDOR.get(vendor.id));
  };

  const skeletonVendors = [
    0,
    1,
    2,
  ];

  return (
    <section className='category-vendors container'>
      <ul className='v-container'>
        <h3 className={ isEmpty ? 'empty v-container' : undefined }>
          {
            isEmpty
              ? <><p className='skeleton-loader' /><p className='skeleton-loader' /></>
              : `${category.name} brands`
          }
        </h3>
        {
          isEmpty
            ? skeletonVendors.map(number => <button key={ number } > <VendorCard /></button>)
            : category.vendors.filter((value, index) => index % 2 === 1)
              .map(vendor => (
                <button
                  className='v-container'
                  key={ vendor.id }
                  onClick={ () => clickVendor(vendor) }
                ><VendorCard vendor={ vendor } />
                </button>
              ))
        }

      </ul>
      <ul className='v-container'>
        {
          isEmpty
            ? skeletonVendors.map(number => <button key={ number } > <VendorCard /></button>)
            : category.vendors.filter((value, index) => index % 2 === 0)
              .map(vendor => (
                <button
                  className='v-container'
                  key={ vendor.id }
                  onClick={ () => clickVendor(vendor) }
                ><VendorCard vendor={ vendor } />
                </button>
              ))
        }
      </ul>
    </section>
  );
}

CategoryVendors.propTypes = { category: PropTypeShapes.category };
