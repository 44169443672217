import React from 'react';
import './Special.css';
import PropTypes from 'prop-types';
import PropTypeShapes from '../../services/Constants/PropTypeShapes.jsx';
import { Voucher } from '../../services/Constants/ObjectDefinitions.js';
import useVoucherProcessor from '../../services/hooks/useVoucherProcessor.js';

/**
 * @param {object} props - the props of the component
 * @param {Voucher} props.voucher - voucher to show as a special
 * @param {Function} props.onClick - what to do onClick of view Voucher button
 * @param {{promotionTagLabel: string}} props.promo - the promotion details of the special
 * @returns {JSX.Element} Special component
 */
export default function Special({ voucher, onClick, promo }) {
  const { getBuyNowPercentValue } = useVoucherProcessor();
  const extraValue = getBuyNowPercentValue(voucher);

  return (
    <div className='special container-v'>
      <header className='v-container'>
        <div>
          <h3>{Math.floor(extraValue)}% More</h3>
          <p>{promo.promotionTagLabel}</p>
        </div>
        <button
          className='secondary-button'
          onClick={ () => onClick(voucher) }
        >
          View Voucher
        </button>
      </header>
    </div>
  );
}

Special.propTypes = {
  onClick: PropTypes.func,
  voucher: PropTypeShapes.voucher,
  textColor: PropTypes.string,
  promo: PropTypes.shape({ promotionTagLabel: PropTypes.string }),
};
