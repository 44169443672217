import React, { useContext, useEffect, useState } from 'react';
import './SnackBar.css';
import { SnackBarContext } from '../../services/context/SnackBarContext.js';

/**
 * @returns {JSX.Element} SnackBar Component
 */
export default function SnackBar() {
  const [hidden, setHidden] = useState(true);
  const { icon, body } = useContext(SnackBarContext) ?? {};

  useEffect(() => {
    if ((icon || body)) {
      setHidden(false);
      const timeout = setTimeout(() => setHidden(true), 1550);
      return () => clearTimeout(timeout);
    }
  }, [
    icon,
    body,
  ]);

  if (!(icon || body)) {
    return null;
  }

  return (
    <div className={ `snack-bar container-v${hidden ? ' animating' : ''}` }>
      {icon}
      {body}
    </div>
  );
}
