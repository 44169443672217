import React, { useContext, useState, Dispatch, SetStateAction } from 'react';
import './ExpandableCouponBottom.css';
import { MoneyFormatterContext } from '../../../services/context/MoneyFormatterContext.js';
import ArrowDown from '../../../assets/ArrowDown.jsx';
import ShareIcon from '../../../assets/ShareIcon.jsx';
import CouponCode from '../../CouponCode/CouponCode.jsx';
import PropTypeShapes from '../../../services/Constants/PropTypeShapes.jsx';
import {
  UpdateCurrentCouponContext,
  UpdateCurrentCouponLoanDetailsContext,
} from '../../../services/context/CurrentCouponContext.js';
import BuyAgain from '../../BuyAgain/BuyAgain.jsx';
import { useNavigate } from 'react-router-dom';
import PaymentRocket from '../../../assets/PaymentRocket.jsx';
import ROUTES from '../../../services/Constants/GlobalRoutes.jsx';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../../services/context/AnalyticsPageContext.js';
import { Gift } from '../../../services/Constants/ObjectDefinitions.js';
import { iOs } from '../../../services/iOs.js';
import { ACTIVE_STATUSES } from '../../../services/ActiveStatuses.js';
import useApis from '../../../services/hooks/useApis.js';
import { BearerTokenContext } from '../../../services/context/BearerTokenContext.js';
import PropTypes from 'prop-types';

/**
 * @param {object} props - the props of the component
 * @param {Gift} props.gift - the gift associated with the coupon
 * @returns {JSX.Element} Coupon Status Component
 */
function Status({ gift }) {
  return (
    <div className='status v-container-h'>
      <p className='redemption'>
        {ACTIVE_STATUSES.includes(gift.status) ? 'Active' : gift.status}
      </p>
      {gift?.advanceHistory && <p className='advance container-v'>Advance</p>}
    </div>
  );
}

Status.propTypes = { gift: PropTypeShapes.gift };

/**
 * @param {object} props - the props of the component
 * @param {Gift} props.gift - the gift associated with the coupon
 * @param {Dispatch<SetStateAction<string>>} props.setLoading - set state for loading bar
 * @returns {JSX.Element} ExpandableCouponBottom Component
 */
export default function ExpandableCouponBottom({ gift, setLoading }) {
  const { getLoanDetails } = useApis();
  const bearerToken = useContext(BearerTokenContext);
  const [expanded, setExpanded] = useState(false);
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const setCurrentCoupon = useContext(UpdateCurrentCouponContext);
  const setLoanDetails = useContext(UpdateCurrentCouponLoanDetailsContext);
  const navigateTo = useNavigate();
  const dateFormatter = new Intl.DateTimeFormat('en-ZA', {
    dateStyle: 'long',
    timeStyle: 'short',
  });

  const redemptionText = gift?.voucher?.redemption.type;
  const analyticsName = useContext(AnalyticsPageContext);
  const pageLinkAnalytics = {
    ...defaultAnalyticsVariables,
    page_name: analyticsName,
    event_name: [events.interaction],
  };
  const flipExpanded = () => {
    setExpanded(prevState => !prevState);
    window.utag?.link({
      ...pageLinkAnalytics,
      link_id: `${pagePrefix}: view code`,
    });
  };

  const onShare = () => {
    setCurrentCoupon(gift);
    window.utag?.link({
      ...pageLinkAnalytics,
      link_id: `${pagePrefix}: share voucher`,
    });
    navigateTo(ROUTES.SHARE);
  };

  const onManagePayments = () => {
    setLoading('Getting Voucher details...');
    getLoanDetails(bearerToken, gift.reservationId)
      .then(data => data.balance ?? {
        fees: 0,
        outstanding: 0,
        principal: 0,
      })
      .then(balance => {
        setLoanDetails(balance);
        setCurrentCoupon(gift);
        navigateTo(ROUTES.MANAGE_ADVANCE);
        window.utag?.link({
          ...pageLinkAnalytics,
          link_id: `${pagePrefix}: manage payments`,
        });
      })
      .finally(() => setLoading(null));
  };

  return (
    <div className='expandable-bottom v-container'>
      <header className='container-v'>
        <div className='v-container'>
          <h3>
            {MoneyFormatter.format(gift.balance)}
          </h3>

          <p className='extra-info smaller'>
            {redemptionText}
          </p>
        </div>

        <Status gift={ gift } />

        <button
          className={ `container-v${expanded ? ' expanded' : ''}` }
          onClick={ flipExpanded }
        >
          {expanded ? 'Hide' : 'View'}

          {' '}
          code
          <ArrowDown />
        </button>
      </header>

      {
        expanded ? <>
          <CouponCode gift={ gift } />

          <section className='extra-info v-container-h'>
            <p>
              Valid until
              {' '}
              {dateFormatter.format(new Date(gift.expiryDate)).replace('at', '|')}
            </p>
          </section>

          <footer className='action-item container'>
            {
              iOs() ? <div /> : (<button
                className='container-v'
                onClick={ onShare }
              >
                Share voucher<ShareIcon />
              </button>)
            }
            {
              gift.advanceHistory ? <button
                className='container-v'
                onClick={ onManagePayments }
              >
                Manage payments
                <PaymentRocket />
              </button>
                : null
            }

            {!gift.advanceHistory && gift.voucher ? <BuyAgain gift={ gift } /> : null}
          </footer>
        </> : null
      }
    </div>
  );
}

ExpandableCouponBottom.propTypes = {
  gift: PropTypeShapes.gift,
  setLoading: PropTypes.func,
};
