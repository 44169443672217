import React, { useContext, useEffect, useState } from 'react';
import useApis from '../services/hooks/useApis.js';
import { BearerTokenContext } from '../services/context/BearerTokenContext.js';
import PropTypes from 'prop-types';
import { HistoryContext, UpdateHistoryContext } from '../services/context/HistoryContext.js';
import { CategoriesContext } from '../services/context/CategoriesContext.js';
import { ConfigContext } from '../services/context/ConfigContext.js';
import { withSentrySpan } from '../services/Sentry.js';
import { startTransaction, captureException, addBreadcrumb } from '@sentry/react';
import { SentryConfig } from '../services/Constants/Sentry.js';
import { Gift } from '../services/Constants/ObjectDefinitions.js';
import { defaultAnalyticsVariables, events, pagePrefix, traceId } from '../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../services/context/AnalyticsPageContext.js';
import { NetworkError } from '../services/Constants/Errors.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function HistoryController({ slot }) {
  const getVouchers = categories => categories.flatMap(category => category.vendors.flatMap(vendor => vendor.vouchers ?? []));
  const [history, setHistory] = useState(/** @type {Array<Gift>} */null);
  const [attempts, setAttempts] = useState(0);
  const maxAttempts = 5;
  const analyticsName = useContext(AnalyticsPageContext);
  const bearerToken = useContext(BearerTokenContext);
  const categories = useContext(CategoriesContext);
  const { getHistory } = useApis();
  const config = useContext(ConfigContext);

  useEffect(() => {
    if (bearerToken && config && !history && attempts < maxAttempts) {
      const historyTransaction = startTransaction(SentryConfig.admin.history.transaction);
      withSentrySpan(
        historyTransaction,
        SentryConfig.admin.history.spans.getHistory,
        () => getHistory(bearerToken)
          .then(data => data.vouchers)
          .then(setHistory)
          .then(() => setAttempts(0))
          .catch(error => {
            if (error instanceof NetworkError) {
              const { response, message } = error;
              window.utag?.link({
                ...defaultAnalyticsVariables,
                page_name: analyticsName,
                event_name: [events.error],
                link_id: `${pagePrefix}: error`,
                event_error_name: 'cvm history error',
                event_error_code: response.headers?.get(traceId),
                event_error_type: 'system error',
              });
              if (response.headers?.get(traceId)) {
                addBreadcrumb({
                  category: 'traceid',
                  message: response.headers?.get(traceId),
                });
              }

              captureException(new Error(`CVM History Error: ${response.status ?? message}`));
            }

            setAttempts(prevState => prevState + 1);
          }),
      )
        .finally(() => historyTransaction.finish());
    }
  }, [
    bearerToken,
    config,
    history,
    attempts,
  ]);

  useEffect(() => {
    if (!history && attempts >= maxAttempts) {
      addBreadcrumb({
        category: 'history fetch attempts',
        message: `${attempts} attempts`,
      });
      captureException(new Error('Failed to fetch history'));
    }
  }, [
    attempts,
    history,
  ]);

  const isAdvance = gift => gift.advanceHistory;

  useEffect(() => {
    if (history && categories) {
      const vouchers = getVouchers(categories);

      for (const gift of history) {
        if (!gift.voucher) {
          const isAssociatedVoucher = voucher => {
            // ID comes in following forms: 'ALL_123', 'ADV_123-BUY_123' or 'ADV_123-BUY-123-1'
            const [advanceId, buyNowId] = voucher.id.split('-', 2);
            return (voucher.id === gift.voucherId || gift.voucherId === (isAdvance(gift) ? advanceId : buyNowId));
          };

          const associatedVoucher = vouchers.find(voucher => isAssociatedVoucher(voucher));

          if (associatedVoucher) {
            gift.voucher = associatedVoucher;
            setHistory([...history]);
          }
        }
      }
    }
  }, [
    history,
    categories,
  ]);

  return (
    <HistoryContext.Provider value={ history }>
      <UpdateHistoryContext.Provider value={ setHistory }>
        {slot}
      </UpdateHistoryContext.Provider>
    </HistoryContext.Provider>
  );
}

HistoryController.propTypes = { slot: PropTypes.element };
