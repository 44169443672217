import React, { useContext, useEffect, useRef, useState } from 'react';
import './CartPayment.css';
import useApis from '../../../services/hooks/useApis.js';
import { processCartIntoRequest } from '../../../services/ResponseProcessor.js';
import { BearerTokenContext, UpdateBearerTokenContext } from '../../../services/context/BearerTokenContext.js';
import { CartContext, UpdateCartContext } from '../../../services/context/CartContext.js';
import { UpdateRocketContext } from '../../../services/context/RocketContext.js';
import { useNavigate } from 'react-router-dom';
import RocketCouponDisplay from '../../RocketCouponDisplay/RocketCouponDisplay.jsx';
import ROUTES from '../../../services/Constants/GlobalRoutes.jsx';
import { defaultAnalyticsVariables, events, pagePrefix, traceId } from '../../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../../services/context/AnalyticsPageContext.js';
import { CategoriesContext } from '../../../services/context/CategoriesContext.js';
import MiniAppMessageTypes from '../../../services/Constants/MiniAppMessageTypes.js';
import { UpdateMiniAppPaymentContext } from '../../../services/context/MiniAppContext.js';
import Loading from '../../Loading/Loading.jsx';
import { UpdateSnackBarContext } from '../../../services/context/SnackBarContext.js';
import CartIcon from '../../../assets/CartIcon.jsx';
import { getVendorCategory, getVoucherVendor } from '../../../services/ReverseHierarchySearch.js';
import { ConfigContext } from '../../../services/context/ConfigContext.js';
import { MoneyFormatterContext } from '../../../services/context/MoneyFormatterContext.js';
import useAuthService from '../../../services/hooks/useAuthService.js';
import { handlePaymentError } from '../../../services/ErrorHandler.jsx';
import HttpCodes from '../../../services/Constants/HttpCodes.js';
import { captureMessage, startTransaction } from '@sentry/react';
import { withSentrySpan, withSentryTransaction } from '../../../services/Sentry.js';
import { SentryConfig } from '../../../services/Constants/Sentry.js';
import { UpdateHistoryContext } from '../../../services/context/HistoryContext.js';
import { CreatePaymentResponse } from '../../../services/Constants/ApiResponses.js';
import {
  getReservationFailureReason,
  NetworkError,
  VoucherReservationError,
} from '../../../services/Constants/Errors.js';
import InfoIcon from '../../../assets/InfoIcon.jsx';

/**
 * @returns {JSX.Element} CartPayment Component
 */
export default function CartPayment() {
  const navigateTo = useNavigate();
  const bearerTokenContext = useContext(BearerTokenContext);
  const cart = useContext(CartContext);
  const { doAuth } = useAuthService();
  const { createPayment, getBearerToken, getCart, addToCart, checkoutCart, reserveOrder, reserveVoucher } = useApis();
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const setCart = useContext(UpdateCartContext);
  const setRocket = useContext(UpdateRocketContext);
  const analyticsName = useContext(AnalyticsPageContext);
  const categories = useContext(CategoriesContext);
  const updateBearerToken = useContext(UpdateBearerTokenContext);
  const setSnackBar = useContext(UpdateSnackBarContext);

  const setPaymentHandler = useContext(UpdateMiniAppPaymentContext);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [loading, setLoading] = useState(/** @type {string} */null);
  const config = useContext(ConfigContext);
  const setHistory = useContext(UpdateHistoryContext);

  const cartIdRef = useRef('');
  const authCodeRef = useRef('');
  const bearerTokenRef = useRef('');
  const checkoutRef = useRef(/** @type {{cartId: string, orderId: number}} */null);
  const createPaymentRef = useRef(/** @type {CreatePaymentResponse} */null);
  const reservationsRef = useRef(/** @type {Array<Promise<Response>>} */null);

  /**
   @param {NetworkError} error - failed network response
   @param {function(string)} retry - retry function for recoverable network errors
   @returns {Promise<void>}
   */
  const handleNetworkError = async (error, retry) => {
    const buyNowNetworkErrorTransaction = startTransaction(SentryConfig.payments.buyNow.networkError.transaction);

    switch (error.response.status) {
      case HttpCodes.UNAUTHORIZED:
      // falls through

      case HttpCodes.FORBIDDEN: {
        await withSentryTransaction({
          sentryTransaction: SentryConfig.payments.buyNow.networkError.transaction,
          exceptionCallback: error_ => setPaymentResponse({
            ok: false,
            error: error_,
          }),
          steps: [
            {
              transaction: SentryConfig.payments.buyNow.networkError.spans.reAuth,
              worker: async () => {
                setLoading('Retrying...');
                authCodeRef.current = await doAuth();
              },
            },
            {
              transaction: SentryConfig.payments.buyNow.networkError.spans.getBearerToken,
              worker: async () => {
                const bearerResponse = await getBearerToken(authCodeRef.current);
                bearerTokenRef.current = bearerResponse.data.tokens.accessToken;
              },
            },
            {
              transaction: SentryConfig.payments.buyNow.networkError.spans.updateBearerAndRetry,
              worker: async () => {
                updateBearerToken(bearerTokenRef.current);
                return await retry(bearerTokenRef.current);
              },
            },
          ],
        });

        break;
      }

      default: {
        window.utag?.link({
          ...defaultAnalyticsVariables,
          page_name: analyticsName,
          event_name: [events.error],
          link_id: `${pagePrefix}: error`,
          event_error_name: `unexpected buynow network error: ${error.response.status}`,
          event_error_code: error.response.headers?.get(traceId),
          event_error_type: 'system error',
        });
        captureMessage(`Unexpected network response in buy now: ${error.response.status}`, 'warning');
        setSnackBar({
          icon: <CartIcon />,
          body: 'Whoops, something went wrong',
        });
        setLoading(null);

        break;
      }
    }

    buyNowNetworkErrorTransaction.finish();
  };

  /**
   * @param {VoucherReservationError} failure - voucher reservation failure
   */
  const handleReservationFailure = failure => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [events.error],
      link_id: `${pagePrefix}: error`,
      event_error_name: 'cvm buynow reservation failure',
      event_error_type: 'user error',
    });
    const { responses } = failure;
    /**
     * @type {Array<NetworkError>}
     */
    const errors = responses.map(({ status, reason }) => (status === 'rejected' ? reason : null));
    Promise.all(errors.map(async (reason, i) => (reason ? ({
      ...cart[i],
      reason: await getReservationFailureReason(reason),
    }) : null)))
      .then(reasons => reasons.filter(Boolean))
      .then(failures => {
        setRocket({
          body: (
            <RocketCouponDisplay
              isFailure
              cart={ failures }
            />),
          header: (
            <>
              <h3>Reservation failure!</h3>
              <p>The following voucher{failures.length === 1 ? '' : 's'} failed reservation.</p>
            </>),
          onContinue: () => {
            navigateTo(ROUTES.CART, { replace: true });
            setCart(cart.filter((value, index) => !errors[index]));
            setSnackBar({
              icon: <CartIcon />,
              body: 'Removed failed items',
            });
          },
        });
        navigateTo(ROUTES.FAILURE);
      });
  };

  const processCart = async bearerToken => {
    await withSentryTransaction({
      sentryTransaction: SentryConfig.payments.buyNow.success.transaction,
      exceptionCallback: error => {
        if (error instanceof NetworkError) handleNetworkError(error, processCart);
        else if (error instanceof VoucherReservationError) handleReservationFailure(error);
      },
      steps: [
        {
          transaction: SentryConfig.payments.buyNow.success.spans.getCartUrl,
          worker: async () => {
            setLoading('Adding everything to your cart...');
            const { cartId } = await getCart(bearerToken);
            cartIdRef.current = cartId;
          },
        },
        {
          transaction: SentryConfig.payments.buyNow.success.spans.updateCartUrl,
          worker: async () => {
            await addToCart(bearerToken, cartIdRef.current, processCartIntoRequest(cart));
          },
        },
        {
          transaction: SentryConfig.payments.buyNow.success.spans.getCheckoutUrl,
          worker: async () => {
            setLoading('Checking out...');
            checkoutRef.current = await checkoutCart(bearerToken, cartIdRef.current);
          },
        },
        {
          transaction: SentryConfig.payments.buyNow.success.spans.reserve,
          worker: async () => {
            setLoading('Reserving your order...');
            reservationsRef.current = cart.map((voucher, sequenceNo) => reserveVoucher(bearerToken, {
              voucher,
              sequenceNo: sequenceNo + 1,
              orderId: checkoutRef.current.orderId,
            }, false));
          },
        },
        {
          transaction: SentryConfig.payments.buyNow.success.spans.reserve,
          worker: async () => {
            const reservations = await Promise.allSettled(reservationsRef.current);
            if (reservations.some(({ status }) => status === 'rejected')) throw new VoucherReservationError(reservations);
            await reserveOrder(bearerToken, checkoutRef.current.orderId, reservations.map(promise => promise.value));
          },
        },
        {
          transaction: SentryConfig.payments.buyNow.success.spans.auth,
          worker: async () => {
            setLoading('Authorizing payment...');
            authCodeRef.current = await doAuth();
          },
        },
        {
          transaction: SentryConfig.payments.buyNow.success.spans.createPaymentUrl,
          worker: async () => {
            setLoading('Getting ready for payment...');
            createPaymentRef.current = await createPayment(bearerToken, authCodeRef.current, checkoutRef.current.orderId);
          },
        },
        {
          transaction: SentryConfig.payments.buyNow.success.spans.setPaymentHandler,
          worker: async () => {
            if (!window.my) {
              // eslint-disable-next-line no-alert
              const mocked = confirm('mock success?');
              setPaymentResponse({ ok: mocked });
            } else {
              const totalCost = cart
                .reduce((total, voucher) => MoneyFormatter.add(total, MoneyFormatter.getValue(voucher.cost)), MoneyFormatter.get(0));
              window.my.postMessage({
                messageType: MiniAppMessageTypes.payment,
                data: {
                  paymentUrl: createPaymentRef.current.redirectActionForm.redirectUrl,
                  adjustData: {
                    token: config.frontend.adjustTokens.paymentOptions.buyNow,
                    revenue: {
                      amount: totalCost.amount,
                      currency: totalCost.unit,
                    },
                  },
                },
              }, '*');
              setPaymentHandler(() => message => setPaymentResponse(message.data));
            }
          },
        },
      ],
    });
  };

  const handleSuccess = () => {
    setRocket({
      analytics: {
        ...defaultAnalyticsVariables,
        event_name: [
          events.pageView,
          events.buyNowSuccess,
          events.transactionSuccess,
        ],
        page_name: `${pagePrefix}: buy now success`,
        product_category: cart.map(voucher => getVendorCategory(categories, getVoucherVendor(categories, voucher))?.name),
        product_quantity: cart.map(() => 1),
        product_name: cart.map(voucher => voucher.name),
        product_price: cart.map(voucher => MoneyFormatter.getValue(voucher.cost).amount),
        product_id: cart.map(voucher => voucher.id),
        transaction_payment_type: 'buy now',
      },
      body: <RocketCouponDisplay cart={ [...cart] } />,
      header: (
        <>
          <h3>Purchase successful!</h3>
          <p>Your voucher{cart.length === 1 ? '' : 's'} will be available in 2 minutes, you can view it under “My Vouchers”.</p>
        </>),
      onContinue: () => navigateTo(ROUTES.HOME, { replace: true }),
    });
    setCart([]);
    navigateTo(ROUTES.SUCCESS);
  };

  useEffect(() => {
    if (paymentResponse) {
      const paymentResponseTransaction = startTransaction(SentryConfig.payments.paymentResponse.buyNow.transaction);
      setLoading(null);
      const handler = paymentResponse.ok
        ? () => {
          setHistory(null);
          captureMessage('Successful payment response received', 'info');
          return withSentrySpan(
            paymentResponseTransaction,
            SentryConfig.payments.paymentResponse.buyNow.spans.success,
            () => handleSuccess(),
          );
        }
        : () => {
          const { resultCode } = paymentResponse;
          return withSentrySpan(
            paymentResponseTransaction,
            SentryConfig.payments.paymentResponse.buyNow.spans.paymentError,
            () => handlePaymentError(resultCode, setSnackBar),
          );
        };

      handler().finally(() => paymentResponseTransaction.finish());
    }
  }, [paymentResponse]);

  const onClick = async () => {
    await processCart(bearerTokenContext);

    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [
        events.interaction,
        events.checkoutStart,
      ],
      link_id: `${pagePrefix}: checkout`,
      product_category: cart.map(voucher => getVendorCategory(categories, getVoucherVendor(categories, voucher))?.name),
      product_quantity: cart.map(() => 1),
      product_name: cart.map(voucher => voucher.name),
      product_price: cart.map(voucher => MoneyFormatter.getValue(voucher.cost).amount),
      product_id: cart.map(voucher => voucher.id),
      cart_quantity: cart.length,
    });
  };

  return (
    <section className='cart-payment v-container'>
      {loading && <Loading headerText={ loading } />}
      <p>By tapping &quot;Proceed to payment&quot; you agree to the Vouchers
        <button onClick={ () => window.my.postMessage({ messageType: MiniAppMessageTypes.termsAndCond }, '*') }><a>Terms & Conditions</a>.</button></p>
      {
        config.frontend.general.maintenanceWarning.flows.buy
        && <p className='warning container-vh'><InfoIcon /> Currently unavailable</p>
      }
      <button
        disabled={ config.frontend.general.maintenanceWarning.flows.buy }
        className='main-button'
        onClick={ onClick }
      >Proceed to payment
      </button>
    </section>
  );
}
