import React, { useContext, useEffect, useState } from 'react';
import Special from '../Special/Special.jsx';
import './Specials.css';
import RadioButton from '../RadioButton/RadioButton.jsx';
import PropTypeShapes from '../../services/Constants/PropTypeShapes.jsx';
import ProductVoucher from '../ProductVoucher/ProductVoucher.jsx';
import Slider from '../Slider/Slider.jsx';
import CloseIcon from '../../assets/CloseIcon.jsx';
import AddToCartButtons from '../AddToCart/AddToCartButtons.jsx';
import PropTypes from 'prop-types';
import { CategoriesCompleteContext, CategoriesContext } from '../../services/context/CategoriesContext.js';
import { PromosContext } from '../../services/context/PromosContext.js';
import useSliding from '../../services/hooks/useSliding.js';
import { getVoucherVendor } from '../../services/ReverseHierarchySearch.js';
import { captureMessage } from '@sentry/react';

/**
 * @returns {JSX.Element} Specials Component
 */
export default function Specials() {
  const categoriesComplete = useContext(CategoriesCompleteContext);
  const [showSpecials, setShowSpecials] = useState(false);
  const [currentPromo, setCurrentPromo] = useState(null);
  const [selectedPromo, setSelectedPromo] = useState(0);
  const categories = useContext(CategoriesContext);
  const promos = useContext(PromosContext);
  const [animation, setAnimation] = useState(0);
  const [doTransition, setDoTransition] = useState(false);
  const orderedPromos = [...promos].sort((a, b) => Number(a.productRanking) - Number(b.productRanking));
  const vendors = categories.flatMap(category => category.vendors);
  const vouchers = vendors.flatMap(vendor => vendor.vouchers ?? []);
  const promoVouchers = orderedPromos.map(promo => ({
    promo,
    voucher: vouchers.find(voucher => voucher.id === promo.id),
  })).filter(({ voucher }) => voucher);

  /**
   * @param {1 | -1} direction to change display
   */
  const changeSpecial = direction => {
    if (promoVouchers.length === 1) return;
    setDoTransition(true);
    setTimeout(() => {
      setAnimation(-direction * 100); // Move out to the direction of swipe (left for increase)
      setTimeout(() => {
        setDoTransition(false);
        setAnimation(direction * 100); // Snap to other side
        setTimeout(() => {
          setDoTransition(true);
          setAnimation(0); // Slide back in
          setSelectedPromo(prevState => prevState + direction);
        }, 50);
      }, 50);
    }, 50);
  };

  const { distance, touching, onTouchEnd, onTouchStart, onTouchMove } = useSliding(distance_ => {
    const isLeft = distance_ < 0;
    const direction = isLeft ? 1 : -1;
    changeSpecial(direction);
  }, false);

  const translation = touching ? `${distance / 5}px` : `${animation}vw`;

  useEffect(() => {
    setShowSpecials(categoriesComplete);
  }, [categoriesComplete]);

  useEffect(() => {
    if (promoVouchers.length > 0 && !currentPromo) {
      const interval = setInterval(() => {
        changeSpecial(1);
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [promoVouchers]);

  if (!showSpecials || promoVouchers.length === 0) {
    return null;
  }

  const { promo, voucher } = promoVouchers.at(selectedPromo % promoVouchers.length) ?? {};
  return (
    <section className='specials v-container'>
      <div
        className='wrapper v-container'
        style={
          {
            backgroundImage: `url(${voucher.productUrl})`,
            transform: `translateX(${translation})`,
            transition: doTransition ? 'transform .2s' : '',
          }
        }
        onTouchStart={ onTouchStart }
        onTouchMove={ onTouchMove }
        onTouchEnd={ onTouchEnd }
      >
        <button
          className='close'
          onClick={ () => setShowSpecials(false) }
        >
          <CloseIcon />
        </button>
        <img
          className='logo'
          onError={
            event => {
              captureMessage(`Failed to load image: ${event.currentTarget.src}`, 'warning');
              event.currentTarget.parentElement.style.backgroundImage = `url(${voucher.productUrl})`;
              event.currentTarget.src = getVoucherVendor(categories, voucher).iconUrl;
            }
          }
          src={ getVoucherVendor(categories, voucher).iconUrl }
          alt={ getVoucherVendor(categories, voucher).name }
        />
        <Special
          voucher={ voucher }
          promo={ promo }
          onClick={ setCurrentPromo }
        />
      </div>
      <ul className='container-h'>
        {
          promoVouchers
            .map((category, index) => (
              <RadioButton
                key={ index }
                selected={ index === (categories.length + (selectedPromo % promoVouchers.length)) % promoVouchers.length }
              />
            ))
        }
      </ul>
      {
        currentPromo
        && <Slider
          onClickOutside={ () => setCurrentPromo(null) }
          slot={
            <ProductVoucher
              voucher={ currentPromo }
              actionItem={
                <AddToCartButtons
                  voucher={ currentPromo }
                />
              }
              descriptionType='buyNow'
            />
          }
        />
      }
    </section>
  );
}

Specials.propTypes = {
  categories: PropTypeShapes.categories,
  cart: PropTypeShapes.cart,
  setCart: PropTypes.func,
};
