import { createContext } from 'react';

export const ConfigContext = createContext({
  backend: {
    cvas: { v1: { loanBalanceUrl: null } },
    cvm: {
      v1: {
        historyVouchersUrl: null,
        voucherReserveUrl: null,
      },
    },
    dxl: { v1: { fsAuthUrl: null } },
    magento: {
      v1: {
        cartAddUrl: null,
        cartCheckoutUrl: null,
        cartCreateUrl: null,
        cartReserveUrl: null,
        catalogueUrl: null,
        promoGetUrl: null,
      },
    },
    uiServices: { v1: { reportAlarmUrl: null } },
  },
  external: {
    cvas: { dxl: { v2: { getEligibilityUrl: null } } },
    dxl: { apiChannel: null },
    magento: {
      channels: {
        // Also includes other channels, but we only care about vpay
        vpay: {
          catalogueId: null,
          store: null,
        },
      },
      normalCurrency: null,
    },
    vodapayGateway: {
      clientId: null,
      dxl: { createPaymentUrl: null },
      v2: { applyAuthCodeUrl: null },
    },
  },
  frontend: {
    adjustTokens: {
      app: null,
      paymentOptions: {
        buyNow: null,
        advance: null,
      },
    },
    general: {
      catalogue: { maxNumCardsToShow: null },
      maintenanceWarning: {
        flows: {
          load: null,
          advance: null,
          buy: null,
        },
        message: null,
      },
    },
  },
});
