import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PromosContext } from '../services/context/PromosContext.js';
import useApis from '../services/hooks/useApis.js';
import { BearerTokenContext } from '../services/context/BearerTokenContext.js';
import { ConfigContext } from '../services/context/ConfigContext.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The PromosController Component
 */
export default function PromosController({ slot }) {
  const [promos, setPromos] = useState([]);
  const [numPromos, setNumPromos] = useState(1); // Assume at least one promo at all time
  const pageSize = 5;
  const bearerToken = useContext(BearerTokenContext);
  const config = useContext(ConfigContext);

  const { getPromos } = useApis();

  useEffect(() => {
    if (!(config && bearerToken)) return;
    if (promos.length < numPromos) {
      const currentPage = Math.ceil((promos.length + 1) / pageSize); // pages start at 1
      getPromos(bearerToken, pageSize, currentPage)
        .then(data => {
          setNumPromos(data.totalCount);
          const jointPromos = [
            ...promos,
            ...data.items,
          ];
          const promoIds = new Set(jointPromos.map(promo => promo.id));
          setPromos([...promoIds].map(id => jointPromos.find(promo => promo.id === id)));
        });
    }
  }, [
    config,
    bearerToken,
    numPromos,
    promos,
  ]);

  return (
    <PromosContext.Provider value={ promos }>
      { slot }
    </PromosContext.Provider>
  );
}

PromosController.propTypes = { slot: PropTypes.element };
