import React from 'react';
import './DualButtons.css';
import PropTypes from 'prop-types';

/**
 * @param {object} props - the props of the component
 * @param {Function} props.primaryOnClick - onClick function for primary button
 * @param {JSX.Element} props.primaryText - component to display inside primary button
 * @param {Function} props.secondaryOnClick - onClick function for secondary button
 * @param {JSX.Element} props.secondaryText - component to display inside secondary button
 * @param {boolean} props.primaryPayment - whether the primary button is a payment button (for styling purposes)
 * @returns {JSX.Element} ManagePaymentButtons Component
 */
export default function DualButtons({ primaryOnClick, primaryText, secondaryOnClick, secondaryText, primaryPayment }) {
  return (
    <section className='dual-buttons v-container'>
      <div className='wrapper container'>
        <button
          className='tertiary-button'
          onClick={ secondaryOnClick }
        >
          {secondaryText}
        </button>
        <button
          className={ `main-button${primaryPayment ? ' payment' : ''}` }
          onClick={ primaryOnClick }
        >
          {primaryText}
        </button>
      </div>
    </section>
  );
}

DualButtons.propTypes = {
  primaryOnClick: PropTypes.func,
  primaryPayment: PropTypes.bool,
  primaryText: PropTypes.element,
  secondaryOnClick: PropTypes.func,
  secondaryText: PropTypes.element,
};
