import React, { useContext, useEffect, useState } from 'react';
import Voucher from '../../components/Voucher/Voucher.jsx';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import StockChecker from '../../components/StockCheck/StockChecker.jsx';
import ProductVoucher from '../../components/ProductVoucher/ProductVoucher.jsx';
import Slider from '../../components/Slider/Slider.jsx';
import VendorHeader from '../../components/Vendor/Header/VendorHeader.jsx';
import VouchersDisplay from '../../components/Vouchers/VouchersDisplay.jsx';
import { EligibilityContext } from '../../services/context/EligibilityContext.js';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import AdvanceDateDetails from '../../components/AdvanceDateDetails/AdvanceDateDetails.jsx';
import { CategoriesCompleteContext, CategoriesContext } from '../../services/context/CategoriesContext.js';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';
import { getVendorCategory, getVoucherVendor } from '../../services/ReverseHierarchySearch.js';
import { CartContext } from '../../services/context/CartContext.js';
import CheckoutButton from '../../components/CheckoutButton/CheckoutButton.jsx';
import StockStatus from '../../services/Constants/StockStatus.js';
import Redirect from '../../components/Redirect/Redirect.jsx';
import { withProfiler } from '@sentry/react';
import { useParams } from 'react-router-dom';
import { useCostSort } from '../../services/hooks/useCostSort.js';
import TopBack from '../../components/TopBack/TopBack.jsx';
import { AdvanceContext, UpdateAdvanceContext } from '../../services/context/AdvanceContext.js';
import { ConfigContext } from '../../services/context/ConfigContext.js';
import InfoIcon from '../../assets/InfoIcon.jsx';
import { createIsAdvanceOptionFilter } from '../../services/EligibilityOffers.js';

/**
 * @returns {JSX.Element} - Vendor page component
 */
function VendorPage() {
  const { id: idParam } = useParams();
  const vendorId = Number(idParam);
  const [currentVoucher, setCurrentVoucher] = useState();
  const [currentAdvance, setCurrentAdvance] = useState();
  const [advancing, setAdvancing] = [
    useContext(AdvanceContext),
    useContext(UpdateAdvanceContext),
  ];
  const categoriesComplete = useContext(CategoriesCompleteContext);
  const cart = useContext(CartContext);
  const config = useContext(ConfigContext);
  const { costSort } = useCostSort();
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const eligibility = useContext(EligibilityContext);
  const categories = useContext(CategoriesContext);
  const vendors = categories.flatMap(category => category.vendors);
  const vendor = vendors.find(value => value.id === vendorId);
  const isEmpty = !vendor;

  const skeletonVouchers = [
    0,
    1,
    2,
  ];

  const isAdvance = voucher => {
    const isAdvanceOption = createIsAdvanceOptionFilter(eligibility, voucher, MoneyFormatter);
    return Boolean(voucher.options?.advance.terms.options?.some(option => isAdvanceOption(option)));
  };

  const isBuyNow = voucher => Boolean(voucher.options?.buyNow.payment.code);

  const analyticsName = `${pagePrefix}: vendor: ${vendor?.name}`;
  useEffect(() => {
    if (vendor) {
      window.utag?.view({
        ...defaultAnalyticsVariables,
        page_name: analyticsName,
        event_name: [
          events.pageView,
          events.productView,
        ],
        product_category: vendor.vouchers?.map(() => getVendorCategory(categories, vendor)?.name),
        product_quantity: vendor.vouchers?.map(() => 1),
        product_name: vendor.vouchers?.map(voucher => voucher.name),
        product_price: vendor.vouchers?.map(voucher => MoneyFormatter.getValue(voucher.cost).amount),
        product_id: vendor.vouchers?.map(voucher => voucher.id),
      });
    }
  }, []);

  if (isEmpty && categoriesComplete) {
    return <Redirect to={ ROUTES.HOME } />;
  }

  const handleAdvance = voucher => {
    setAdvancing({
      voucher,
      date: null,
    });
    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [events.interaction],
      link_id: `${pagePrefix}: advance now: ${vendor.name}`,
      product_category: getVendorCategory(categories, getVoucherVendor(categories, voucher)).name,
      product_name: voucher.name,
      product_price: MoneyFormatter.getValue(voucher.cost).amount,
      product_id: voucher.id,
      product_quantity: 1,
    });
  };

  const getAdvanceActionItem = voucher => {
    if (config.frontend.general.maintenanceWarning.flows.advance) return <p className='warning container-vh'><InfoIcon /> Currently unavailable</p>;

    return voucher.stock.status === StockStatus.inStock
      ? (
        <button
          disabled={ config.frontend.general.maintenanceWarning.flows.advance }
          className='secondary-button'
          onClick={ () => handleAdvance(voucher) }
        >Advance now</button>)
      : <>Out of stock</>;
  };

  const closeAdvance = () => {
    setAdvancing(null);
    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: `${pagePrefix}: payment due date selection`,
      event_name: [events.interaction],
    });
  };

  const { vouchers, products, advances } = !(vendor?.vouchers) ? {
    vouchers: [],
    products: [],
    advances: [],
  } : {
    vouchers: vendor.vouchers.filter(voucher => voucher.stock.type === 'virtual')
      .filter(value => isBuyNow(value))
      .sort(costSort)
      .map(voucher => (
        <Voucher
          key={ voucher.id }
          title={
            <>
              {`Pay ${MoneyFormatter.format(MoneyFormatter.getValue(voucher.cost))} get `}
              <em>
                {MoneyFormatter.format(MoneyFormatter.getValue(voucher.options.buyNow.value))}
              </em>
            </>
          }
          addedValue={
            `Get ${
              MoneyFormatter.format(MoneyFormatter.subtract(
                MoneyFormatter.getValue(voucher.cost),
                MoneyFormatter.getValue(voucher.options.buyNow.value),
              ))
            } extra`
          }
          actionItem={ <StockChecker voucher={ voucher } /> }
          onClick={ () => setCurrentVoucher(voucher) }
        />
      )),
    products: vendor.vouchers.filter(voucher => voucher.stock.type !== 'virtual')
      .filter(value => isBuyNow(value))
      .sort(costSort)
      .map(voucher => (
        <ProductVoucher
          descriptionType='buyNow'
          key={ `${voucher.id}_product` }
          voucher={ voucher }
          actionItem={ <StockChecker voucher={ voucher } /> }
        />
      )),
    advances: vendor.vouchers
      .filter(value => isAdvance(value))
      .filter(voucher => eligibility?.tcl && MoneyFormatter.getValue(voucher.cost).amount <= eligibility.tcl.amount)
      .sort(costSort)
      .map(voucher => (
        <Voucher
          title={
            <>
              Get
              {' '}
              <em>
                {MoneyFormatter.format(MoneyFormatter.getValue(voucher.options.advance.value))}
              </em>
            </>
          }
          addedValue={ <>Pay later</> }
          actionItem={ getAdvanceActionItem(voucher) }
          key={ voucher.id }
          onClick={ () => setCurrentAdvance(voucher) }
        />
      )),
  };

  const displayAdvanceDetails = () => currentAdvance
    && <Slider
      onClickOutside={ () => setCurrentAdvance(null) }
      slot={
        <ProductVoucher
          descriptionType='advance'
          voucher={ currentAdvance }
          actionItem={ getAdvanceActionItem(currentAdvance) }
        />
      }
    />;

  const displayAdvanceDateSelection = () => advancing
    && <Slider
      onClickOutside={ closeAdvance }
      slot={ <AdvanceDateDetails /> }
    />;

  const displayVoucherDetails = () => currentVoucher
    && <Slider
      onClickOutside={ () => setCurrentVoucher(null) }
      slot={
        <ProductVoucher
          descriptionType='buyNow'
          voucher={ currentVoucher }
          actionItem={ <StockChecker voucher={ currentVoucher } /> }
        />
      }
    />;

  return (
    <AnalyticsPageContext.Provider value={ analyticsName }>
      <TopBack />
      <VendorHeader vendor={ vendor } />
      {
        eligibility?.tcl
        && <VouchersDisplay
          title={ <h4>Buy now, pay later</h4> }
          onLoading={
            <>
              {skeletonVouchers.map(number => <Voucher key={ number } />)}
            </>
          }
          onEmpty={ isEmpty ? null : <p>Uh oh! looks like there aren&apos;t any advances for {vendor.name} at the moment. Check back later</p> }
          isLoading={ !categoriesComplete }
          vouchers={ advances }
        />
      }
      <VouchersDisplay
        title={ <h4>Buy now, get more</h4> }
        onLoading={
          <>
            {skeletonVouchers.map(number => <Voucher key={ number } />)}
          </>
        }
        onEmpty={ isEmpty ? null : <p>Uh oh! looks like there aren&apos;t any vouchers for {vendor.name} at the moment. Check back later</p> }
        isLoading={ !categoriesComplete }
        vouchers={
          [
            ...vouchers,
            ...products,
          ]
        }
      />
      {displayVoucherDetails()}
      {displayAdvanceDetails()}
      {displayAdvanceDateSelection()}
      { cart.length > 0 && <CheckoutButton /> }
    </AnalyticsPageContext.Provider>
  );
}

export default withProfiler(VendorPage);
