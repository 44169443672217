import React, { useEffect, useRef, useState } from 'react';
import './Slider.css';
import PropTypes from 'prop-types';
import CloseIcon from '../../assets/CloseIcon.jsx';
import useSliding from '../../services/hooks/useSliding.js';

/**
 *
 * @param {object} props - the props of the component
 * @param {JSX.Element} props.slot - What to show inside the Slider
 * @param {Function} props.onClickOutside - What to do on clicking outside the slider
 * @returns {JSX.Element} Slider Component
 */
export default function Slider({ slot, onClickOutside }) {
  const ref = useRef(/** @type {HTMLDivElement} */null);
  const [animating, setAnimating] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setAnimating(false), 50);
    return () => clearTimeout(timeout);
  }, []);

  const hideAnimation = () => {
    setAnimating(typeof onClickOutside === 'function');
    setTimeout(() => onClickOutside?.(), 250);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        hideAnimation();
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const {
    onTouchStart,
    onTouchEnd,
    onTouchMove,
    distance,
    touching,
  } = useSliding(distance_ => { if (distance_ > 0) hideAnimation(); });
  const translation = distance < 0 ? -Math.log1p(Math.abs(distance)) : distance;

  return (
    <div className='slider'>
      <div
        ref={ ref }
        style={
          touching ? {
            transform: `translateY(${translation}px)`,
            transition: 'transform 400ms cubic-bezier(.13,1,.64,1)',
          } : {}
        }
        className={ animating ? 'animating' : '' }
      >
        <header
          className='close v-container-h'
          onTouchStart={ onTouchStart }
          onTouchMove={ onTouchMove }
          onTouchEnd={ onTouchEnd }
        >
          <button
            className='bar'
            onClick={ hideAnimation }
          >
          </button>
          <button
            className='icon'
            onClick={ hideAnimation }
          ><CloseIcon />
          </button>
        </header>
        <main>
          {slot}
        </main>
      </div>
    </div>
  );
}

Slider.propTypes = {
  slot: PropTypes.element,
  onClickOutside: PropTypes.func,
};
