import { createContext, Context, Dispatch, SetStateAction } from 'react';
import { SnackBarProperties } from '../Constants/ObjectDefinitions.js';

/**
 * @type {Context<SnackBarProperties>}
 */
export const SnackBarContext = createContext();

/**
 * @type {Context<Dispatch<SetStateAction<SnackBarProperties>>>}
 */
export const UpdateSnackBarContext = createContext(({ icon, body }) => ({
  icon,
  body,
}));
